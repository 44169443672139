import { useState } from "react";
import axios from "axios";
import { SERVER } from "../../util/server";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${SERVER}/api/login`, {
        email: username,
        password: password,
      });

      const { token } = response.data;

      sessionStorage.setItem("authToken", token);

      navigate("/admin/users");
    } catch (error) {
      toast.error("Usuario ou Senha inválidas");
      console.error("Erro ao fazer login:", error);
    }
  };

  return (
    <>
      <div className="container">
        <h1>Login</h1>

        <form id="adminLoginForm" onSubmit={handleLogin}>
          <label htmlFor="username">Usuário:</label>
          <br />
          <input
            type="text"
            id="username"
            name="username"
            required
            placeholder="Digite seu usuário"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <br />

          <label htmlFor="password">Senha:</label>
          <br />
          <input
            type="password"
            id="password"
            name="password"
            required
            placeholder="Digite sua senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <button className="btn start-btn" type="submit">
            Login
          </button>
        </form>
      </div>
    </>
  );
}

export default Login;
