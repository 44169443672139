import { useState } from "react";
import "./Form.css";
import axios from "axios";
import { toast } from "sonner";
import { Navigate, useNavigate } from "react-router-dom";
import { SERVER } from "../../util/server";
import Cookies from "js-cookie";

function Form() {
  const [email, setEmail] = useState();
  const navigate = useNavigate();

  async function verificarEmail() {
    if (!email) {
      toast.info("Digite um E-mail");
      return;
    }

    try {
      const encodedEmail = encodeURIComponent(email).replace(/\./g, "%2E");
      const response = await axios.get(
        `${SERVER}/api/user/email/${encodedEmail}`
      );

      if (response.status === 200) {
        if (response.data.prova === 1) {
          toast.info("Prova já iniciada!");
        } else {
          const id = response.data.id;
          const res = await axios.put(`${SERVER}/api/user/active/p/${id}`);

          if (res.status === 200) {
            sessionStorage.clear();
            Cookies.remove("currentTextIndex");
            Cookies.remove("currentQuestionIndex");

            toast.success("E-mail verificado com sucesso!");
            sessionStorage.setItem("emailVerified", "true");
            sessionStorage.setItem("id", id);
            navigate("/home");
          } else {
            toast.error("Erro ao ativar o usuário!");
          }
        }
      } else {
        toast.warning("Erro desconhecido na verificação de e-mail!");
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        toast.warning("E-mail não encontrado");
      } else {
        toast.error("Erro ao verificar o e-mail!");
        console.error("Erro:", error);
      }
    }
  }

  return (
    <>
      <div id="emailSection" className="section">
        <h1 style={{ color: "#3498db", fontSize: "1.5em" }}>
          Digite seu email para iniciar
        </h1>
        <p style={{ color: "#747474" }}>
          <em>O mesmo e-mail que você enviou para a equipe pedagogica.</em>
        </p>
        <input
          type="email"
          id="emailInput"
          placeholder="Digite seu email"
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button
          id="verifyEmailButton"
          onClick={() => verificarEmail()}
          className="btn"
        >
          Verificar Email
        </button>
        <p id="emailError" style={{ color: "red", display: "none" }}>
          Email não encontrado. Contate o administrador.
        </p>
      </div>
    </>
  );
}

export default Form;
