import React, { useState, useEffect } from "react";
import Quiz from "../Quiz/Quiz";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";

function InicioQuestionario() {
  const [quizStarted, setQuizStarted] = useState(false);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [hasProgress, setHasProgress] = useState(false);
  const [hasBlocked, setHasBlocked] = useState(false);

  useEffect(() => {
    const email = sessionStorage.getItem("emailVerified");
    if (email === "true") {
      setEmailVerified(true);
    }

    const finished = sessionStorage.getItem("quizFinished");
    if (finished === "true") {
      setQuizCompleted(true);
    }

    const blocked = Cookies.get("quizRevoked");
    if (blocked) {
      setHasBlocked(true);
    }

    const current = Cookies.get("currentQuestionIndex");
    console.log(current);
    if (Number(current) > 0) {
      setHasProgress(true);
    }
  }, []);

  if (quizCompleted) {
    return <Navigate to="/" replace />;
  }

  if (!emailVerified) {
    return <p>Você precisa verificar seu email para iniciar o teste.</p>;
  }

  if (quizStarted || hasProgress || hasBlocked) {
    return <Quiz onQuizFinish={() => {}} />;
  }

  const startQuiz = () => {
    setQuizStarted(true);
  };

  return (
    <div className="container">
      <div id="introduction" className="section">
        <div className="instruction-header no-select">
          <h1>BEM-VINDO AO TESTE DE NIVELAMENTO GLI</h1>
          <p className="no-select">
            <em>
              Siga as instruções abaixo com atenção para se preparar bem para o
              teste:
            </em>
          </p>
        </div>
        <div className="instruction-content no-select">
          <h3>INSTRUÇÕES IMPORTANTES:</h3>
          <ul className="instruction-list no-select">
            <li>
              <strong>1 minuto por questão:</strong> Cada pergunta deve ser
              respondida dentro de 1 minuto.
            </li>
            <li>
              <strong>Proibido retornar a questões anteriores:</strong> Você não
              poderá revisar ou modificar respostas de perguntas já respondidas.
            </li>
            <li>
              <strong>Saída da aba:</strong> Se você sair da aba mais de 2
              vezes, perderá o acesso ao questionário.
            </li>
            <li>
              <strong>Sem respostas automáticas:</strong> Se o tempo para uma
              questão se esgotar, ela será registrada como não respondida.
            </li>
          </ul>
          <div className="suggestions no-select">
            <h3>Sugestões para o seu sucesso:</h3>
            <ul>
              <li>
                <strong>Escolha um lugar tranquilo:</strong> Busque um ambiente
                calmo para realizar a prova.
              </li>
              <li>
                <strong>Desligue outros aparelhos eletrônicos:</strong> Evite
                interrupções desligando dispositivos desnecessários.
              </li>
              <li>
                <strong>Reserve pelo menos 20 minutos:</strong> Certifique-se de
                ter tempo livre suficiente para completar o teste.
              </li>
              <li>
                <strong>Mantenha a calma:</strong> Fique tranquilo, você se
                sairá muito bem!
              </li>
            </ul>
          </div>
        </div>
        <div className="instruction-footer">
          <button
            id="startButton"
            className="btn start-btn"
            onClick={startQuiz}
          >
            Iniciar Teste
          </button>
        </div>
      </div>
    </div>
  );
}

export default InicioQuestionario;
