import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Users.css";
import { toast } from "sonner";
import { SERVER } from "../../util/server";

function Users() {
  const [candidates, setCandidates] = useState([]);
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [idade, setIdade] = useState("");
  const [disponibilidade, setDisponibilidade] = useState("");
  const [atendente, setAtendente] = useState("");
  const [interesse, setInteresse] = useState("");
  const [modalidade, setModalidade] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [admins, setAdmins] = useState([]);

  const token = sessionStorage.getItem("authToken");

  useEffect(() => {
    async function fetchAdmins() {
      try {
        const response = await axios.get(`${SERVER}/api/admin/view`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setAdmins(response.data.admins);
        console.log(admins);
      } catch (error) {
        toast.error(
          error.response?.data?.error || "Erro ao carregar administradores."
        );
      }
    }

    fetchAdmins();
  }, []);

  const validateForm = () => {
    if (!nome) {
      toast.error("O campo Nome é obrigatório");
      return false;
    }
    if (!email) {
      toast.error("O campo Email é obrigatório");
      return false;
    }
    if (!idade) {
      toast.error("O campo Idade é obrigatório");
      return false;
    }
    if (!disponibilidade) {
      toast.error("O campo Disponibilidade é obrigatório");
      return false;
    }
    if (!interesse) {
      toast.error("O campo Interesse é obrigatório");
      return false;
    }
    if (!modalidade) {
      toast.error("O campo Modalidade é obrigatório");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const response = await axios.post(
        `${SERVER}/api/user`,
        {
          nome,
          email,
          idade,
          disponibilidade,
          interesse,
          modalidade,
          atendente,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 400) {
        toast.warning("E-mail já cadastrado");
      }

      setCandidates([...candidates, response.data.user]);

      setNome("");
      setEmail("");
      setIdade("");
      setDisponibilidade("");
      setInteresse("");
      setModalidade("");
      setIsModalOpen(false);
      toast.success("Salvo com sucesso!");
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Erro ao cadastrar candidato."
      );
    }
  };

  const filteredCandidates = candidates.filter((candidate) =>
    candidate.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    getUsers();
  }, []);

  async function getUsers() {
    try {
      const response = await axios.get(`${SERVER}/api/user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data) {
        const usersEmails = response.data
          .filter((user) => user.prova !== 1)
          .map((user) => ({
            atendente: user.atendente,
            nome: user.nome,
            email: user.email,
            idade: user.idade,
            disponibilidade: user.disponibilidade,
            interesse: user.interesse,
            modalidade: user.modalidade,
          }));

        setCandidates(usersEmails);
      }
    } catch (error) {
      console.error("Erro ao buscar candidatos:", error);
      toast.error("Erro ao buscar candidatos.");
    }
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleInfoClick = (candidate) => {
    setSelectedCandidate(candidate);
  };

  return (
    <>
      <div className="container">
        <div className="header">
          <h3>Cadastro de Candidatos</h3>
        </div>

        <div className="form-section">
          <button className="btn start-btn" onClick={toggleModal}>
            Cadastrar Candidato
          </button>
        </div>

        <div className="search-section">
          <input
            type="text"
            placeholder="Buscar candidato pelo e-mail..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <h4>Candidatos que ainda não realizaram o nivelamento</h4>

        <div className="table-container">
          <table id="candidatesTable" className="styled-table">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Email</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {filteredCandidates.length > 0 ? (
                filteredCandidates.map((candidate, index) => (
                  <tr key={index}>
                    <td>{candidate.nome}</td>
                    <td>{candidate.email}</td>
                    <td>
                      <button
                        className="btn info-btn"
                        onClick={() => handleInfoClick(candidate)}
                      >
                        Info
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3">Nenhum candidato encontrado</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {isModalOpen && (
          <div className="modal-overlay">
            <div className="modal-content">
              <h2>Cadastrar Novo Candidato</h2>
              <form id="candidateForm" onSubmit={handleSubmit}>
                <label htmlFor="atendente">Nome do Assessor:</label>
                <select
                  value={atendente}
                  onChange={(e) => setAtendente(e.target.value)}
                >
                  <option value="">Selecione um assessor</option>
                  {admins.map((admin) =>
                    admin.tipo == 1 ? (
                      <option key={admin.id} value={admin.email}>
                        {admin.nome} - {admin.email}
                      </option>
                    ) : null
                  )}
                </select>
                <br />
                <br />
                <label htmlFor="nome">Nome do Candidato:</label>
                <input
                  type="text"
                  id="nome"
                  name="nome"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  placeholder="Digite o nome do candidato"
                />
                <br />
                <label htmlFor="email">Email do Candidato:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Digite o email do candidato"
                />
                <br />
                <label htmlFor="idade">Idade do Candidato:</label>
                <input
                  type="text"
                  id="idade"
                  name="idade"
                  value={idade}
                  onChange={(e) => setIdade(e.target.value)}
                  placeholder="Digite a idade do Candidato"
                />
                <br />

                <label>Disponibilidade de horário:</label>
                <select
                  value={disponibilidade}
                  onChange={(e) => setDisponibilidade(e.target.value)}
                >
                  <option value="">Selecione uma Opção</option>
                  <option value="0">Das 06h às 08h</option>
                  <option value="1">Das 08h às 12h</option>
                  <option value="2">Das 12h às 15h</option>
                  <option value="3">Das 15h às 17h</option>
                  <option value="4">Das 17h às 21h</option>
                  <option value="5">Das 21h às 23h</option>
                  <option value="6">Outro</option>
                </select>

                <br />

                <label>Interesse:</label>
                <div>
                  <select
                    value={interesse}
                    onChange={(e) => setInteresse(e.target.value)}
                  >
                    <option value="">Selecione uma Opção</option>
                    <option value="0">Trabalho – Negócios (Corporate)</option>
                    <option value="1">Fins Generalizados (Universal)</option>
                    <option value="2">Viagens (Focus)</option>
                    <option value="3">Acadêmico (Focus)</option>
                    <option value="4">Somente Conversação</option>
                    <option value="5">Outro</option>
                  </select>
                </div>

                <br />

                <label>Modalidade:</label>
                <div>
                  <select
                    value={modalidade}
                    onChange={(e) => setModalidade(e.target.value)}
                  >
                    <option value="">Selecione uma Opção</option>
                    <option value="0">Individual</option>
                    <option value="1">Turma</option>
                  </select>
                </div>

                <button className="btn start-btn" type="submit">
                  Cadastrar
                </button>
                <button className="btn cancel-btn" onClick={toggleModal}>
                  Cancelar
                </button>
              </form>
            </div>
          </div>
        )}

        {selectedCandidate && (
          <div className="modal-overlay">
            <div className="modal-content">
              <h2>Informações do Candidato</h2>
              <p>
                <strong>Assessor:</strong> {selectedCandidate.atendente}
              </p>
              <p>
                <strong>Nome:</strong> {selectedCandidate.nome}
              </p>
              <p>
                <strong>Email:</strong> {selectedCandidate.email}
              </p>
              <p>
                <strong>Idade:</strong> {selectedCandidate.idade}
              </p>
              <p>
                <strong>Disponibilidade:</strong>{" "}
                {(() => {
                  switch (selectedCandidate.disponibilidade) {
                    case "0":
                      return "Das 06h às 08h";
                    case "1":
                      return "Das 08h às 12h";
                    case "2":
                      return "Das 12h às 15h";
                    case "3":
                      return "Das 15h às 17h";
                    case "4":
                      return "Das 17h às 21h";
                    case "5":
                      return "Das 21h às 23h";
                    case "6":
                      return "Outro";
                    default:
                      return "Não informado";
                  }
                })()}
              </p>
              <p>
                <strong>Interesse:</strong>{" "}
                {(() => {
                  switch (selectedCandidate.interesse) {
                    case "0":
                      return "Trabalho – Negócios (Corporate)";
                    case "1":
                      return "Fins Generalizados (Universal)";
                    case "2":
                      return "Viagens (Focus)";
                    case "3":
                      return "Acadêmico (Focus)";
                    case "4":
                      return "Somente Conversação";
                    case "5":
                      return "Outro";
                    default:
                      return "Não informado";
                  }
                })()}
              </p>
              <p>
                <strong>Modalidade:</strong>{" "}
                {selectedCandidate.modalidade === "0" ? "Individual" : "Turma"}
              </p>
              <button
                className="btn cancel-btn"
                onClick={() => setSelectedCandidate(null)}
              >
                Fechar
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Users;
