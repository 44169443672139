import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Form from "./pages/Home/Form";
import InicioQuestionario from "./pages/InicioQuestionario/InicioQuestionario";
import Login from "./admin/Login/Login";
import Users from "./admin/Users/Users";
import ProtectedRoute from "./util/ProtectedRoute";
import ProtectedRouteEmail from "./util/VerifyEmail";
import { Toaster } from "sonner";
function App() {
  {
    /* <ProtectedRouteEmail> */
  }
  {
    /* </ProtectedRouteEmail> */
  }

  return (
    <>
      <Toaster
        position="top-center"
        toastOptions={{
          style: {
            fontSize: "24px",
            fontWeight: "bold",
            textAlign: "center",
          },
        }}
        expand
        duration={3000}
        richColors={true}
      />
      <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<Form />} />

            <Route
              path="/home"
              element={
                <ProtectedRouteEmail>
                  <InicioQuestionario />
                </ProtectedRouteEmail>
              }
            />

            <Route path="/admin/login" element={<Login />} />

            <Route
              path="/admin/users"
              element={
                <ProtectedRoute>
                  <Users />
                </ProtectedRoute>
              }
            />

            <Route path="*" element={<h1>404</h1>} />
          </Routes>
        </Router>
      </div>
    </>
  );
}

export default App;
