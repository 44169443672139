import React, { useState, useEffect, useRef } from "react";
import { toast } from "sonner";
import "./Quiz.css";
import { SERVER } from "../../util/server";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const questionData = [
  {
    sectionIntro: "Reading Session",
    text: "Technology plays a vital role in our daily lives, impacting everything from how we communicate to how we work and entertain ourselves. With the rapid advancement of technology, staying updated with the latest trends and innovations has become essential. From smartphones to artificial intelligence, the digital landscape continues to evolve, presenting both opportunities and challenges. As we navigate this ever-changing terrain, developing digital literacy skills becomes increasingly important. Whether it's understanding data privacy or mastering new software, the ability to adapt and learn in a technology-driven world is key to success. By embracing technology and continuously expanding our knowledge, we can harness its power to improve our lives and shape the future.",
    questions: [
      {
        question:
          "According to the text, technology has little to no impact on our daily lives.",
        options: ["True", "False"],
        correctAnswer: "False",
        points: 2,
      },
      {
        question:
          "The text suggests that staying updated with the latest trends and innovations in technology is not important.",
        options: ["True", "False"],
        correctAnswer: "False",
        points: 2,
      },
      {
        question:
          "The text implies that developing digital literacy skills is essential in today's technology-driven world.",
        options: ["True", "False"],
        correctAnswer: "True",
        points: 2,
      },
    ],
  },
  {
    text: "The development of modern cities has significantly altered the way humans interact with their environment. While urbanization brings many benefits such as improved infrastructure, better access to healthcare, and more job opportunities, it also presents several challenges. One of the primary issues is the impact on natural ecosystems. As cities expand, forests, wetlands, and other natural habitats are often destroyed to make way for roads, buildings, and other structures. This can lead to a decline in biodiversity as species lose their homes and food sources. Additionally, urban areas tend to produce large amounts of pollution, including air and water contaminants, which can affect both human health and the environment. On the positive side, many cities are now implementing green initiatives, such as planting trees, creating parks, and promoting the use of renewable energy sources. These efforts aim to mitigate the negative effects of urbanization and create more sustainable, eco-friendly urban spaces.",
    questions: [
      {
        question:
          "According to the text, what is one of the main environmental challenges caused by urbanization?",
        options: [
          "a) Lack of job opportunities.",
          "b) Destruction of natural habitats.",
          "c) Overpopulation in cities.",
          "d) Limited healthcare access.",
        ],
        correctAnswer: "b) Destruction of natural habitats.",
        points: 3,
      },
      {
        question:
          "What is the author’s attitude toward the relationship between urbanization and the environment?",
        options: [
          "a) Urbanization is entirely negative for the environment.",
          "b) Urbanization is positive, with no major consequences.",
          "c) Urbanization has both positive and negative effects on the environment.",
          "d) The author believes that urbanization’s benefits outweigh the environmental risks.",
        ],
        correctAnswer:
          "c) Urbanization has both positive and negative effects on the environment.",
        points: 3,
      },
      {
        question:
          "What can be inferred about the future of urban areas based on the information in the text?",
        options: [
          "a) Cities will likely continue to expand without considering environmental impacts.",
          "b) Urban areas will focus more on sustainability and reducing pollution.",
          "c) The destruction of natural habitats will increase as more people move to cities.",
          "d) Urbanization will lead to the extinction of many species.",
        ],
        correctAnswer:
          "b) Urban areas will focus more on sustainability and reducing pollution.",
        points: 3,
      },
    ],
  },
  {
    text: "The theory of quantum mechanics revolutionized our understanding of the atomic and subatomic worlds. Unlike classical mechanics, which describes the motion of macroscopic objects, quantum mechanics deals with particles at the quantum level, where phenomena such as superposition and entanglement occur. Superposition refers to the ability of particles to exist in multiple states simultaneously until measured, while entanglement describes a phenomenon where particles become interconnected and the state of one instantaneously influences the state of another, regardless of the distance separating them. These principles have profound implications for our understanding of reality and have led to the development of technologies such as quantum computing and quantum cryptography. Despite its successes, quantum mechanics remains a challenging and often counterintuitive field, continuously evolving as new experiments and theories emerge.",
    questions: [
      {
        question:
          "What does the principle of superposition involve according to the text?",
        options: [
          "a) The ability of particles to exist in a single state until measured.",
          "b) The phenomenon where particles exist in multiple states simultaneously until observed.",
          "c) The instantaneous influence of one particle on another over any distance.",
          "d) The connection between particles that does not involve their state changes.",
        ],
        correctAnswer:
          "b) The phenomenon where particles exist in multiple states simultaneously until observed.",
        points: 5,
      },
      {
        question:
          "How does the principle of entanglement affect our understanding of particle interaction?",
        options: [
          "a) It shows that particle interaction is limited to their local environment.",
          "b) It illustrates that particle states are independent of each other, regardless of distance.",
          "c) It reveals that particle states are interconnected and influence each other instantaneously, regardless of distance.",
          "d) It confirms that particles only interact in predictable, non-instantaneous ways.",
        ],
        correctAnswer:
          "c) It reveals that particle states are interconnected and influence each other instantaneously, regardless of distance.",
        points: 5,
      },
    ],
  },
  // Listening 1
  {
    sectionIntro: "Listening Session",
    text: "Listening 1",
    videoUrl: "https://www.youtube.com/embed/gin16OLQdtg",
    questions: [
      {
        question: "Ella...",
        options: [
          "a. works at the library.",
          "b. likes watching movies on TV.",
          "c. is at school.",
        ],
        correctAnswer: "c. is at school.",
        points: 1,
      },
      {
        question: "Where was Ella’s Japanese cooking course?",
        options: ["a. at the library", "b. at the college", "c. at school"],
        correctAnswer: "b. at the college",
        points: 1,
      },
      {
        question: "How many weeks was Ella’s Japanese cooking course?",
        options: ["a. seven", "b. eight", "c. nine"],
        correctAnswer: "b. eight",
        points: 1,
      },
      {
        question: "At the moment, Jack is learning...",
        options: [
          "a. how to record music.",
          "b. how to play the violin.",
          "c. about classical music.",
        ],
        correctAnswer: "a. how to record music.",
        points: 1,
      },
      {
        question: "What instrument can’t Jack play well?",
        options: ["a. the violin", "b. the drums", "c. the guitar"],
        correctAnswer: "c. the guitar",
        points: 1,
      },
    ],
  },
  // Listening 2
  {
    text: "Listening 2",
    videoUrl: "https://www.youtube.com/embed/Ekxxfq5QNJM",
    questions: [
      {
        question: "What is Toby's view of salary?",
        options: [
          "a. He'd rather have a well-paid job than an interesting job.",
          "b. He wants an interesting job, even if it's badly paid.",
          "c. He will do unpaid hours if it allows him to further his career.",
        ],
        correctAnswer:
          "a. He'd rather have a well-paid job than an interesting job.",
        points: 2,
      },
      {
        question: "What is Toby's view of working hours?",
        options: [
          "a. He will do extra hours in order to get a promotion.",
          "b. He works overtime because he needs the money.",
          "c. He avoids working any more hours than necessary.",
        ],
        correctAnswer: "c. He avoids working any more hours than necessary.",
        points: 2,
      },
      {
        question: "What is Toby's view of work flexibility?",
        options: [
          "a. Luckily, he does not require flexible working hours.",
          "b. His working conditions are flexible enough for his needs.",
          "c. He wishes that his work offered more flexibility.",
        ],
        correctAnswer:
          "b. His working conditions are flexible enough for his needs.",
        points: 2,
      },
      {
        question: "What is Toby's view of vacation time?",
        options: [
          "a. He can't travel to the places he'd like to go.",
          "b. Vacation time is not very important to him.",
          "c. He wishes he had more vacation time.",
        ],
        correctAnswer: "c. He wishes he had more vacation time.",
        points: 2,
      },
      {
        question: "What is Toby's view of commuting?",
        options: [
          "a. The time he spends commuting is increasing.",
          "b. He finds commuting easier now than in the past.",
          "c. He feels lucky that his commute to work is shorter now.",
        ],
        correctAnswer: "b. He finds commuting easier now than in the past.",
        points: 2,
      },
    ],
  },
  // Listening 3
  {
    text: "Listening 3",
    videoUrl: "https://www.youtube.com/embed/1EpHTyeQxgw",
    questions: [
      {
        question: "When is running out of fuel on a German motorway allowed?",
        options: [
          "a. Never.",
          "b. Only when it’s an emergency.",
          "c. Only when you can prevent traffic congestion or an accident.",
        ],
        correctAnswer: "a. Never.",
        points: 2,
      },
      {
        question: "What must you do before taking your car in Denmark?",
        options: [
          "a. Check that the children are sitting safely.",
          "b. Check that your children are using their seats.",
          "c. Check that there are no kids near your car.",
        ],
        correctAnswer: "c. Check that there are no kids near your car.",
        points: 2,
      },
      {
        question: "What is true in Spain?",
        options: [
          "a. You can’t wear sandals unless coming from the beach.",
          "b. You must always wear safe footwear.",
          "c. You can wear comfortable shoes or no shoes at all.",
        ],
        correctAnswer: "b. You must always wear safe footwear.",
        points: 2,
      },
      {
        question: "What does the video imply about the law in Alaska?",
        options: [
          "a. You cannot carry a pet unless it’s tied.",
          "b. You can’t let your dog climb onto the roof of your car.",
          "c. You mustn’t carry your pet on the roof of your car.",
        ],
        correctAnswer: "c. You mustn’t carry your pet on the roof of your car.",
        points: 2,
      },
      {
        question: "When can you drive in Manila?",
        options: [
          "a. It depends on the number on your number plate.",
          "b. If you can drive on Monday, you cannot drive on Tuesday.",
          "c. It depends on the colour of your number plate.",
        ],
        correctAnswer: "a. It depends on the number on your number plate.",
        points: 2,
      },
    ],
  },
  {
    sectionIntro: "Session Language Structure & Vocabulary",
    text: "LANGUAGE STRUCTURE & VOCABULARY:",
    questions: [
      {
        question: "Choose the odd one out",
        options: ["Mother", "friend", "father", "uncle"],
        correctAnswer: "friend",
        points: 1,
      },
      {
        question: "Choose the odd one out",
        options: ["China", "British", "Spanish", "French"],
        correctAnswer: "China",
        points: 1,
      },
      {
        question: `"By the time we __________ to the station, the train had already left."`,
        options: ["a) get", "b) got", "c) getting", "d) gets"],
        correctAnswer: "b) got",
        points: 2,
      },
      {
        question: "Who.............................this letter? Suwit did.",
        options: ["WROTE", "WRITING", "WRITE"],
        correctAnswer: "WROTE",
        points: 1,
      },
      {
        text: "Choose the option that correctly completes the sentence, maintaining both the meaning and grammatical accuracy.",
        question: `"Had the company __________ the importance of cybersecurity earlier, they would have avoided the data breach."`,
        options: [
          "a) been recognizing",
          "b) recognized",
          "c) recognizes",
          "d) recognize",
        ],
        correctAnswer: "b) recognized",
        points: 2,
      },
      {
        question: "My birthday is ............... May 22.",
        options: ["IN", "ON", "OF", "AT"],
        correctAnswer: "ON",
        points: 1,
      },
      {
        text: `Read the following sentence and identify the most appropriate word or phrase to fill in the blank based on both meaning and structure.`,
        question: `"Despite the overwhelming evidence, many individuals still __________ to acknowledge the gravity of the situation, insisting that the data is either exaggerated or misinterpreted."`,
        options: ["a) deny", "b) refuse", "c) neglect", "d) fail"],
        correctAnswer: "b) refuse",
        points: 2,
      },
      {
        text: "Choose the word that best completes the sentence while maintaining both the meaning and grammatical accuracy.",
        question: `Sentence: "Her explanation was so __________ that even the most skeptical audience members were left with no questions."`,
        options: ["a) convoluted", "b) obscure", "c) lucid", "d) ambiguous"],
        correctAnswer: "c) lucid",
        points: 2,
      },
      {
        question: `"If I had known you were coming, I __________ a cake."`,
        options: [
          "a) will bake",
          "b) would bake",
          "c) would have baked",
          "d) had baked",
        ],
        correctAnswer: "c) would have baked",
        points: 2,
      },
      {
        question: `"Had it not been for his quick thinking, the situation __________ much worse."`,
        options: [
          "a) will become",
          "b) would become",
          "c) would have been",
          "d) had become",
        ],
        correctAnswer: "c) would have been",
        points: 2,
      },
      {
        question: `"By the time they __________ the airport, the plane had already taken off."`,
        options: [
          "a) reached",
          "b) have reached",
          "c) were reaching",
          "d) will reach",
        ],
        correctAnswer: "a) reached",
        points: 2,
      },
    ],
  },
  {
    sectionIntro: "Writing Session",
    type: "multi-select",
    questions: [
      {
        question: "Check the Correct sentences. ( 3 Phrases are Correct )",
        options: [
          "You is a teacher",
          "They doesn't like to play soccer.",
          "I have seen her yesterday.",
          "She has been working here for five years.",
          "If I was you, I would take the job.",
          "Having studied for hours, he felt ready for the exam.",
          "No sooner had we arrived than it started to rain.",
        ],
        correctAnswer: [
          "She has been working here for five years.",
          "Having studied for hours, he felt ready for the exam.",
          "No sooner had we arrived than it started to rain.",
        ],
        points: 7,
      },
    ],
  },

  /*  */

  {
    questions: [
      {
        text: 'Portuguese: "Eu gosto de maçãs."',
        question: "Choose the correct translation:",
        options: [
          "a) I like apples.",
          "b) I likes apples.",
          "c) I am like apples.",
          "d) I liking apples.",
        ],
        correctAnswer: "a) I like apples.",
        points: 2,
      },
      {
        text: 'Portuguese: "Ela não está em casa."',
        question: "Choose the correct translation:",
        options: [
          "a) She don’t at home.",
          "b) She is not at home.",
          "c) She are not at home.",
          "d) She not is at home.",
        ],
        correctAnswer: "b) She is not at home.",
        points: 2,
      },
      {
        text: 'Portuguese: "Nós estamos esperando por você há duas horas."',
        question: "Choose the correct translation:",
        options: [
          "a) We are waiting for you since two hours.",
          "b) We have been waiting for you for two hours.",
          "c) We wait for you since two hours.",
          "d) We are waited for you for two hours.",
        ],
        correctAnswer: "b) We have been waiting for you for two hours.",
        points: 2,
      },
      {
        text: 'Portuguese: "Se ele tivesse estudado mais, teria passado no exame."',
        question: "Choose the correct translation:",
        options: [
          "a) If he studied more, he would have passed the exam.",
          "b) If he had studied more, he would have passed the exam.",
          "c) If he would have studied more, he passed the exam.",
          "d) If he had study more, he would pass the exam.",
        ],
        correctAnswer:
          "b) If he had studied more, he would have passed the exam.",
        points: 2,
      },
      {
        text: 'Portuguese: "Embora ele tenha trabalhado muito, não conseguiu terminar o projeto a tempo."',
        question: "Choose the correct translation:",
        options: [
          "a) Although he worked a lot, he didn’t manage to finish the project in time.",
          "b) Despite of working hard, he didn't finish the project on time.",
          "c) Although he had worked a lot, he didn't finish the project in time.",
          "d) Even though he worked hard, he couldn't finish the project on time.",
        ],
        correctAnswer:
          "d) Even though he worked hard, he couldn't finish the project on time.",
        points: 2,
      },
      {
        question: "Which of these is a complete and correct sentence?",
        options: [
          "a) I like to swim in the summer.",
          "b) In the summer swim like.",
          "c) Swimming summer I like.",
          "d) Summer is swim I like.",
        ],
        correctAnswer: "a) I like to swim in the summer.",
        points: 2,
      },
      {
        text: "Portuguese sentence: Eu estou escrevendo uma carta para o meu amigo.",
        question:
          "What is the correct way to write this sentence in formal English?",
        options: [
          "a) I writing a letter to my friend.",
          "b) I write letter to my friend.",
          "c) I am writing a letter to my friend.",
          "d) I wrote a letter for my friend.",
        ],
        correctAnswer: "c) I am writing a letter to my friend.",
        points: 2,
      },
      {
        question:
          "How should this sentence be rewritten to improve clarity and grammar?",
        originalSentence:
          "I think that when you are going to write a letter it’s better if you can focus and try not making many mistakes.",
        options: [
          "a) I think writing a letter is easier when you focus and avoid making mistakes.",
          "b) I think writing letters easier with focus and less mistakes.",
          "c) Writing letters is easier if you focus, but it is difficult.",
          "d) When you write, don’t make mistakes and focus.",
        ],
        correctAnswer:
          "a) I think writing a letter is easier when you focus and avoid making mistakes.",
        points: 2,
      },
      {
        question:
          "What is the most effective way to structure this argument in a formal essay?",
        originalSentence:
          '"Some people believe that technology is harmful, but others disagree. Technology helps in many ways. It also has negative impacts."',
        options: [
          "a) While some argue that technology is harmful, it is undeniable that technology provides numerous benefits, though it does come with some negative consequences.",
          "b) Technology is harmful, but others don’t agree. It’s important to recognize the benefits.",
          "c) People think that technology is helpful and harmful, and there are many opinions about it.",
          "d) Technology is sometimes harmful and helpful; there are many views on it.",
        ],
        correctAnswer:
          "a) While some argue that technology is harmful, it is undeniable that technology provides numerous benefits, though it does come with some negative consequences.",
        points: 2,
      },
      {
        question:
          "Which of the following sentences demonstrates the most effective use of parallel structure in a formal essay?",
        originalSentence:
          "In order to succeed in the modern business world, one must be prepared to work long hours, understand the needs of the market, and adaptability is crucial.",
        options: [
          "a) In order to succeed in the modern business world, one must be prepared to work long hours, understand the needs of the market, and be adaptable.",
          "b) To succeed in the modern business world, long hours must be worked, understanding the market needs is essential, and adaptability is necessary.",
          "c) Success in the business world requires working long hours, understanding the market, and to be adaptable.",
          "d) Success in modern business requires long hours, understanding the needs of the market, and adaptability.",
        ],
        correctAnswer:
          "a) In order to succeed in the modern business world, one must be prepared to work long hours, understand the needs of the market, and be adaptable.",
        points: 2,
      },
    ],
  },
];

function Quiz({ onQuizFinish }) {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [timeLeft, setTimeLeft] = useState(60);
  const [answers, setAnswers] = useState({});
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [score, setScore] = useState(0);
  const [finished, setFinished] = useState(false);
  const [videosWatched, setVideosWatched] = useState(
    Array(questionData.length).fill(false)
  );
  const intervalRef = useRef(null);
  const [showIntro, setShowIntro] = useState(true);

  const currentText = questionData[currentTextIndex];
  const currentQuestion = currentText.questions[currentQuestionIndex];

  const [tabExitCount, setTabExitCount] = useState(0);
  const [accessRevoked, setAccessRevoked] = useState(false);

  useEffect(() => {
    if (finished) {
      sessionStorage.removeItem("emailVerified");
    }
  }, [finished]);

  useEffect(() => {
    const revoked = Cookies.get("quizRevoked");
    if (revoked) {
      setAccessRevoked(true);
    } else {
      document.addEventListener("visibilitychange", handleVisibilityChange);
    }

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [tabExitCount]);

  useEffect(() => {
    if (!accessRevoked) {
      document.addEventListener("visibilitychange", handleVisibilityChange);
      /* window.addEventListener("blur", handleBlur); */
    }
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      /* window.removeEventListener("blur", handleBlur); */
    };
  }, [tabExitCount, accessRevoked]);

  useEffect(() => {
    const savedCurrentTextIndex = Cookies.get("currentTextIndex");
    const savedCurrentQuestionIndex = Cookies.get("currentQuestionIndex");

    if (savedCurrentTextIndex && savedCurrentQuestionIndex) {
      setCurrentTextIndex(parseInt(savedCurrentTextIndex));
      setCurrentQuestionIndex(parseInt(savedCurrentQuestionIndex));
    }
  }, []);

  useEffect(() => {
    Cookies.set("currentTextIndex", currentTextIndex, { expires: 1 });
    Cookies.set("currentQuestionIndex", currentQuestionIndex, { expires: 1 });
  }, [currentTextIndex, currentQuestionIndex]);

  const handleVisibilityChange = () => {
    if (document.hidden) {
      setTabExitCount((prevCount) => prevCount + 1);
    }
  };

  const handleBlur = () => {
    setTabExitCount((prevCount) => prevCount + 1);
  };

  useEffect(() => {
    const savedCurrentTextIndex = Cookies.get("currentTextIndex");
    const savedCurrentQuestionIndex = Cookies.get("currentQuestionIndex");

    if (savedCurrentTextIndex && savedCurrentQuestionIndex) {
      setCurrentTextIndex(parseInt(savedCurrentTextIndex));
      setCurrentQuestionIndex(parseInt(savedCurrentQuestionIndex));
    }
  }, []);

  useEffect(() => {
    if (tabExitCount == 1) {
      toast.info("ATENÇÃO");
      toast.info("Você saiu da tela! Mais uma chance!");
    }
    if (tabExitCount == 2) {
      toast.info("ATENÇÃO");
      toast.info("Você saiu da tela! Mais a última chance!");
    }
    if (tabExitCount >= 3) {
      // Cookies.set("quizRevoked", true, { expires: 1 });
      setAccessRevoked(true);
      toast.error("ATENÇÃO");
      toast.error(
        "Você saiu da aba mais de 2 vezes. Acesso ao questionário revogado."
      );

      Cookies.remove("currentTextIndex");
      Cookies.remove("currentQuestionIndex");
      sessionStorage.clear();
    }
  }, [tabExitCount]);

  useEffect(() => {
    if (
      !accessRevoked &&
      (!currentText.videoUrl || videosWatched[currentTextIndex])
    ) {
      startTimer();
    }
    return () => clearInterval(intervalRef.current);
  }, [
    currentQuestionIndex,
    videosWatched,
    currentText.videoUrl,
    accessRevoked,
    showIntro,
  ]);

  const startTimer = () => {
    clearInterval(intervalRef.current);

    if (showIntro && currentText.sectionIntro) {
      setTimeLeft(99999);
      return;
    }

    if (currentText.videoUrl && !videosWatched[currentTextIndex]) {
      setTimeLeft(99999);
      return;
    }

    setTimeLeft(60);

    intervalRef.current = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          handleTimeExpired();
          clearInterval(intervalRef.current);
        }
        return prevTime - 1;
      });
    }, 1000);
  };

  const handleTimeExpired = () => {
    toast.warning("ATENÇÃO");
    toast.warning("Tempo esgotado! Questão marcada como 'não respondido'.");
    setAnswers((prev) => ({
      ...prev,
      [`Text ${currentTextIndex} Question ${currentQuestionIndex}`]:
        "não respondido",
    }));
    moveToNextQuestion();
  };

  const moveToNextQuestion = () => {
    let nextQuestionIndex = currentQuestionIndex;
    let nextTextIndex = currentTextIndex;

    if (currentQuestionIndex < currentText.questions.length - 1) {
      nextQuestionIndex = currentQuestionIndex + 1;
    } else if (currentTextIndex < questionData.length - 1) {
      nextTextIndex = currentTextIndex + 1;
      nextQuestionIndex = 0;
      setShowIntro(true);
    } else {
      finishQuiz();
      return;
    }

    setCurrentTextIndex(nextTextIndex);
    setCurrentQuestionIndex(nextQuestionIndex);
    startTimer();
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [level, setLevel] = useState("");

  const finishQuiz = async () => {
    if (isSubmitting) return;

    setIsSubmitting(true);

    let nivel = "";

    if (score <= 20) {
      nivel = "A1 (Iniciante)";
    } else if (score <= 40) {
      nivel = "A2 (Iniciante)";
    } else if (score <= 60) {
      nivel = "B1 (Pré-intermediário)";
    } else if (score <= 80) {
      nivel = "B2 (Intermediário Superior)";
    } else if (score <= 90) {
      nivel = "C1 (Avançado)";
    } else {
      nivel = "C2 (Proficiência)";
    }

    setLevel(nivel);

    const userIdFromSession = sessionStorage.getItem("id");
    console.log("User ID:", userIdFromSession);

    try {
      const response = await axios.post(`${SERVER}/api/sendQuizResult`, {
        userId: userIdFromSession,
        score,
        nivel,
      });

      if (response.status === 200) {
        toast.success("Resultados salvos!");
      } else {
        toast.error("ATENÇÃO");
        toast.error("Erro ao salvar resultados.");
      }
    } catch (error) {
      toast.error("ATENÇÃO");
      toast.error("Erro ao salvar resultados.");
    }

    toast.success("Quiz finalizado!");

    Cookies.remove("currentTextIndex");
    Cookies.remove("currentQuestionIndex");

    setFinished(true);

    sessionStorage.clear();

    if (onQuizFinish) {
      onQuizFinish();
    }
  };

  const handleAnswerSelection = (option) => {
    if (currentText.type === "multi-select") {
      setSelectedAnswers((prevSelected) => {
        if (prevSelected.includes(option)) {
          return prevSelected.filter((item) => item !== option);
        } else {
          return [...prevSelected, option];
        }
      });
    } else {
      setSelectedAnswer(option);
    }
  };

  const handleSubmitAnswer = () => {
    if (currentText.type === "multi-select") {
      if (selectedAnswers.length === 0) {
        toast.error("ATENÇÃO");
        toast.error(
          "Por favor, selecione uma resposta antes de continuar sua prova."
        );
        return;
      }

      clearInterval(intervalRef.current);

      const isCorrect =
        selectedAnswers.every((answer) =>
          currentQuestion.correctAnswer.includes(answer)
        ) && selectedAnswers.length === currentQuestion.correctAnswer.length;

      setAnswers((prev) => ({
        ...prev,
        [`Text ${currentTextIndex} Question ${currentQuestionIndex}`]:
          selectedAnswers,
      }));

      if (isCorrect) {
        // toast.success("Respostas corretas!");
        setScore(score + currentQuestion.points);
      } else {
        // toast.error("Respostas incorretas.");
      }

      setSelectedAnswers([]);
    } else {
      if (selectedAnswer === null) {
        toast.error("ATENÇÃO");
        toast.error("Selecione uma resposta antes de continuar.");
        return;
      }

      clearInterval(intervalRef.current);

      setAnswers((prev) => ({
        ...prev,
        [`Text ${currentTextIndex} Question ${currentQuestionIndex}`]:
          selectedAnswer,
      }));

      if (selectedAnswer === currentQuestion.correctAnswer) {
        /* toast.success("Resposta correta!"); */
        setScore(score + currentQuestion.points);
      } else {
        /* toast.error("Resposta incorreta."); */
      }

      setSelectedAnswer(null);
    }

    moveToNextQuestion();
  };

  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  const handleStartSection = () => {
    setShowIntro(false);
    startTimer();
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "F12") {
        e.preventDefault();
      }

      if (e.ctrlKey && e.shiftKey && e.key === "I") {
        e.preventDefault();
      }

      if (e.ctrlKey && e.shiftKey && e.key === "J") {
        e.preventDefault();
      }

      if (e.ctrlKey && e.key === "U") {
        e.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    let threshold = 160;
    const checkDevTools = () => {
      const start = performance.now();
      debugger;
      const end = performance.now();

      if (end - start > threshold) {
        alert("Feche o DevTools!");
      }
    };

    const intervalId = setInterval(checkDevTools, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const handleVideoWatched = () => {
    setVideosWatched((prevWatched) => {
      const updatedWatched = [...prevWatched];
      updatedWatched[currentTextIndex] = true;
      return updatedWatched;
    });
    startTimer();
  };

  if (accessRevoked) {
    return (
      <div className="quiz-container">
        <h1>Acesso ao questionário revogado</h1>
        <p>
          Você saiu da aba mais de duas vezes e perdeu o acesso ao questionário.
        </p>
      </div>
    );
  }

  return (
    <div className="quiz-container">
      {!finished && (
        <>
          {showIntro && currentText.sectionIntro ? (
            <>
              <h2>NIVELAMENTO GLI</h2>
              <h1>{currentText.sectionIntro}</h1>{" "}
              <button className="btn btn-primary" onClick={handleStartSection}>
                Começar Seção
              </button>
            </>
          ) : (
            <>
              {currentText.videoUrl && !videosWatched[currentTextIndex] && (
                <>
                  <h2>IMPORTANTE: ASSISTA AO VÍDEO ANTES DE INICIAR O QUIZ</h2>
                  <p>
                    <strong>Clique no botão vermelho do play</strong> para
                    iniciar o vídeo.
                  </p>
                  <p>
                    <strong>Assista ao vídeo com atenção.</strong>
                    <br /> Você pode assistir quantas vezes quiser antes de
                    começar o quiz.
                  </p>

                  <p>
                    <strong>Faça anotações pertinentes,</strong> pois haverá uma
                    série de perguntas relacionadas ao conteúdo do vídeo.
                  </p>

                  <p>
                    <strong>
                      Após assistir ao vídeo, clique em "Começar o Quiz."
                    </strong>
                    Lembre-se de que não será possível rever o vídeo após
                    iniciar o quiz.{" "}
                  </p>

                  <div className="video-container">
                    <iframe
                      width="560"
                      height="315"
                      src={currentText.videoUrl}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                    <br />
                    <button
                      className="btn btn-primary"
                      onClick={handleVideoWatched}
                    >
                      Começar Quiz
                    </button>
                  </div>
                </>
              )}

              {(!currentText.videoUrl || videosWatched[currentTextIndex]) && (
                <>
                  <p className="tempo-total">{timeLeft}</p>
                  <br />
                  <br />
                  <p className="title no-select">{currentText.text}</p>
                  <br />
                  <p className="title no-select">{currentQuestion.text}</p>
                  <p className="title no-select">
                    {currentQuestion?.originalSentence}
                  </p>
                  <h2 className="no-select">{currentQuestion.question}</h2>
                  <div className="options">
                    {currentText.type === "multi-select"
                      ? currentQuestion.options.map((option, index) => (
                          <label key={index} className="no-select">
                            <input
                              type="checkbox"
                              className="inputcheckbox"
                              value={option}
                              checked={selectedAnswers.includes(option)}
                              onChange={() => handleAnswerSelection(option)}
                            />
                            {option}
                          </label>
                        ))
                      : currentQuestion.options.map((option, index) => (
                          <label key={index} className="no-select">
                            <input
                              type="radio"
                              className="inputradio"
                              value={option}
                              checked={selectedAnswer === option}
                              onChange={() => handleAnswerSelection(option)}
                            />
                            {option}
                          </label>
                        ))}
                  </div>
                  <button
                    className="btn btn-success"
                    onClick={handleSubmitAnswer}
                  >
                    Próxima Pergunta
                  </button>
                </>
              )}
            </>
          )}
        </>
      )}

      {finished && (
        <div>
          <h2>Quiz Finalizado!</h2>
          <p>
            <strong>
              Obrigado por dedicar seu tempo para realizar o teste de
              nivelamento!{" "}
            </strong>
          </p>
          <p>
            Em breve, entraremos em contato com você. Sua participação é muito
            importante para nós!{" "}
          </p>
          <h2>
            Parabéns! Seu nível é <strong>{level}</strong>
          </h2>
          {score && score > 40 ? (
            <>
              <p>
                Estaremos entrando em contato para agendar a proval oral
                adicional!
              </p>
            </>
          ) : null}

          <p>
            Agradecemos mais uma vez e desejamos sucesso em sua jornada de
            aprendizado!
          </p>
        </div>
      )}
    </div>
  );
}

export default Quiz;
