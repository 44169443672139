import { Navigate } from "react-router-dom";
import { useEffect, useState } from "react";

const ProtectedRouteEmail = ({ children }) => {
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const emailVerified = sessionStorage.getItem("emailVerified");
    console.log(emailVerified);
    if (emailVerified) {
      setIsVerified(true);
    }
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <div>Verificando acesso...</div>;
  }

  if (!isVerified) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default ProtectedRouteEmail;
